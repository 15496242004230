export default {
  en: {
    admin: 'Administrator',
    email: 'Email',
    prenom: 'First name',
    nom: 'Last name',
    adresse: 'Address',
    ville: 'City',
    codePostal: 'Postal code',
    pays: 'Country',
    province: 'Province',
    etat: 'State',
    mobile: 'Mobile',
    telephone: 'Phone number',
    retourAccueil: 'Back to home',
    accueil: 'Home',
    aPropos: 'About',
    infoDC:
      'Please note that registration for community distribution is closed for this year.',
    mesCoupons: 'My coupons (community distribution)',
    mesPermis: 'My licences (hunting and fishing)',
    commanderPermis: 'Order confirmation',
    distributionCommunautaire: 'Community distribution',
    updateProfile: 'Update my profile',
    inscriptionAssemblee: 'Registration form for the general meeting',
    espaceVideo: 'Video space',
    elections: 'Elections',
    reponsesCandidatsElections: 'Candidates answers',
    monCouponPromo: 'My promo code',
    importationCouponsPromo: 'Import promo coupons',
    monProfil: 'My profile',
    deconnexion: 'Log out',
    confirmationCommande: 'Order confirmation',
    merci: 'Thank you',
    erreurPaiement:
      'An error has occurred, please contact a member of our team.',
    noCertificat: 'Hunter certificate number',
    boite: {
      poisson: 'Box of fish',
      gibier: 'Box of game',
      saumon: 'Box of salmon',
    },
    boutons: {
      connexion: 'Login',
      sauvegarder: 'Save changes',
      supprimer: 'Remove',
      passerCommande: 'Confirm order',
      passerCommandePermis: 'Confirm order',
    },
    oui: 'Yes',
    non: 'No',
    erreur: 'Error',
    succes: 'Success',
    visionnerAssembleeGenerale: 'Watch the general meeting',
  },
  fr: {
    admin: 'Administrateur',
    email: 'Courriel',
    prenom: 'Prénom',
    nom: 'Nom',
    adresse: 'Adresse',
    ville: 'Ville',
    codePostal: 'Code postal',
    pays: 'Pays',
    province: 'Province',
    etat: 'État',
    mobile: 'Mobile',
    telephone: 'Téléphone',
    retourAccueil: "Retour à l'accueil",
    accueil: 'Accueil',
    aPropos: 'À propos',
    infoDC:
      "Prendre note que l'inscription à la distribution communautaire est terminée pour cette année.",
    mesCoupons: 'Mes coupons (distribution communautaire)',
    mesPermis: 'Mes permis (chasse et pêche)',
    commanderPermis: 'Confirmation de la commande',
    distributionCommunautaire: 'Distribution communautaire',
    updateProfile: 'Mettre à jour mon profil',
    inscriptionAssemblee: "Formulaire d'inscription à l'assemblée générale",
    espaceVideo: 'Espace vidéo',
    elections: 'Élections',
    monCouponPromo: 'Mon code promo',
    importationCouponsPromo: 'Importation de coupons promo',
    reponsesCandidatsElections: 'Réponses des candidats',
    confirmationCommande: 'Confirmation de la commande',
    monProfil: 'Mon profil',
    deconnexion: 'Déconnexion',
    merci: 'Merci',
    erreurPaiement:
      'Une erreur est survenue, veuillez contacter un membre de notre équipe.',
    noCertificat: 'No. de certificat du chasseur',
    boite: {
      poisson: 'Boîte de poisson',
      gibier: 'Boîte de gibier',
      saumon: 'Boîte de saumon',
    },
    boutons: {
      connexion: 'Se connecter',
      sauvegarder: 'Sauvegarder',
      supprimer: 'Supprimer',
      passerCommande: 'Confirmer la commande',
      passerCommandePermis: 'Passer la commande',
    },
    oui: 'Oui',
    non: 'Non',
    erreur: 'Erreur',
    succes: 'Succès',
    visionnerAssembleeGenerale: "Visionner l'assemblée générale",
  },
};
